import type { ServerError, ServerParseError } from "@apollo/client";

import { isDevelopment } from "@shared/utils/fullscriptEnv/fullscriptEnv";

type NetworkError = Error | ServerError | ServerParseError;

const isServerError = (error: NetworkError): error is ServerError => {
  return !!(error as ServerError).result;
};

const isServerParseError = (error: NetworkError): error is ServerParseError => {
  return !!(error as ServerParseError).response;
};

const is403 = (error: NetworkError): boolean => {
  return (isServerError(error) || isServerParseError(error)) && error.statusCode === 403;
};

const unauthorizedErrorRedirect = (error: NetworkError) => {
  const has403 = !!error && is403(error);

  if (has403 && !isDevelopment()) {
    window.location.assign("/");
  }
};

export { unauthorizedErrorRedirect };
